import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import TextShared from "../../components/Shared/TextShared";
import NumberFormatShared from "../../components/Shared/NumberFormatShared";
import {DatePickerShared, SelectShared} from "../../components";
import {getGeoObject} from "../../redux/actions/geo/geoActions";
import {useDispatch, useSelector} from "react-redux";
import {register} from "../../redux/actions/userActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {getGenderAll} from "../../redux/actions/genericActions";
import {useHistory} from "react-router-dom";
import {GENERAL_ROUTE} from "../../routes/const";
import {GeoObjectSelect, RegionSelect} from "../../components/Select/domainSelects";

const SignUpPerson = (props) => {
    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const [userName, setUserName] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [birthDay, setBirthDay] = useState(new Date())
    const [middleName, setMiddleName] = useState('')
    const [identityNumber, setIdentityNumber] = useState({formattedValue: '',
        value: ''})
    const [number, setNumber] = useState({formattedValue: '',
        value: ''})
    const [email, setEmail] = useState('')
    const [gender, setGender] = useState(1)
    const {genderAll} = useSelector((state) => state.generic);
    useEffect(() => {
        dispatch(getGenderAll())
    }, [dispatch])
    const [regionId, setRegionId] = useState('')
    const [addressId, setAddressId] = useState('')
    const [addressLine, setAddressLine] = useState('')
    const handleSubmit = async (event) => {
        event.preventDefault();
        const person = {
            firstName: firstName,
            lastName: lastName,
            addressLine,
            secondName: middleName,
            fullName: lastName + ' ' + firstName + ' ' + middleName,
            identityNumber: identityNumber.value,
            addressId,
            birthDay: birthDay,
            phoneNumber: number.value,
            email: email,
            genderId: gender
        }
        if (password === confirmPassword) {
            try {
                dispatch(register(userName, password, person))
            } catch (e) {
                alert(e.response?.data)
            }
        } else {
            alert('Пароли не совпадают')
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 3 }}>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextShared value={userName} setValue={setUserName} required label="Логин"/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={lastName} setValue={setLastName} required label="Фамилия"/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={firstName} setValue={setFirstName} required label="Имя"/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={middleName} setValue={setMiddleName} required label="Отчество"/>
            </Grid>
            <Grid item xs={6}>
                <RegionSelect
                    label={"Регион"}
                    fullWidth
                    value={regionId}
                    onChange={val => setRegionId(val)}
                />
            </Grid>
            <Grid item xs={6}>
                <GeoObjectSelect
                    label={"Город"}
                    fullWidth
                    value={addressId}
                    onChange={val => setAddressId(val)}
                    arrayFilter={_ => regionId ? _.parentId == regionId : true}
                    // disabled={!regionId}
                    // parentId={regionId}
                    // parentKey="parentId"
                />
            </Grid>
            <Grid item xs={6}>
                <TextShared value={addressLine} setValue={setAddressLine} required={false} label="Адрес"/>
            </Grid>
            <Grid item xs={6}>
                <NumberFormatShared format="############" mask="_" placeholder="ИИН" value={identityNumber} setValues={setIdentityNumber}/>
            </Grid>
            <Grid item xs={6}>
                <DatePickerShared setValue={setBirthDay} value={birthDay} maxValue={birthDay} label="Дата рождения"/>
            </Grid>
            <Grid item xs={6}>
                <NumberFormatShared format="+7 (###) ###-##-##" mask="_" placeholder="+7 (999) 333-22-11" value={number} setValues={setNumber}/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={email} setValue={setEmail} required label="Email" type="email"/>
            </Grid>
            <Grid item xs={6}>
                <SelectShared array={genderAll} required label="Пол" value={gender} setValue={setGender}/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={password} setValue={setPassword} required label="Придумайте пароль" type="password"/>
            </Grid>
            <Grid item xs={6}>
                <TextShared value={confirmPassword} setValue={setConfirmPassword} required label="Повторите пароль" type="password"/>
            </Grid>
        </Grid>
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Регистрация
            </Button>
        </Box>
    );
}

export default SignUpPerson;
